<template>
  <div class="Reel" :style="`--cover:url('${url_encode(getPostCover(items[current]))}');`"></div>
</template>

<script>
import HomeFeed from "../feed/HomeFeed.js";
import Reels from "./reels.js";
export default {
  mixins: [HomeFeed, Reels],
  props: ["post", "current"],
};
</script>

<style lang="scss" scoped>
.Reel {
  position: relative;
  width: 100%;
  height: 100%;
  background-image: var(--cover);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  overflow: hidden;
  &.CurrentReel {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    background-image: var(--cover);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: $mpadding/2;
    pointer-events: none;
    background-color: $alto;
  }
  .ReelsHeader {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 3;
  }
}
</style>
